import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { BadgeProps, BadgeVariant, StyledBadgeBox } from '../Badge/Badge';
import { buildBadgeIconConfig } from '../Badge/variants';
import Icon from '../Icon/Icon';

export type Props = BadgeProps;

const NotificationContainer = styled(StyledBadgeBox)<{
  variant: BadgeVariant;
}>(
  ({ theme, variant }) => css`
    border-left: 5px solid ${theme.colours.border[variant]};
  `,
);

const Notification = ({
  children,
  icon,
  variant = 'information',
  ...props
}: Props) => {
  return (
    <NotificationContainer
      {...props}
      alignItems="center"
      borderRadius="borderRadiusS"
      display="flex"
      gap={16}
      p={16}
      variant={variant}
    >
      {icon && (
        <Icon icon={icon} size={20} {...buildBadgeIconConfig(variant)} />
      )}
      {children}
    </NotificationContainer>
  );
};

export default Notification;
