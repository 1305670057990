import { Theme } from '@emotion/react';
import { IconColourProp } from '@ui-v2/types/props';
import { BadgeVariant } from './Badge';

type BadgeVariantStyleConfig = {
  background: string;
  color: string;

  svg: {
    fill: string;
    stroke: string;
  };
};

export const buildBadgeVariant = (
  variant: BadgeVariant,
  theme: Theme,
): BadgeVariantStyleConfig => {
  return {
    background: theme.colours.surface[variant],
    color: theme.colours.text.on[variant],

    svg: {
      fill: theme.colours.icons.on[variant],
      stroke: theme.colours.icons.on[variant],
    },
  };
};

type IconStyleConfig = {
  colour: IconColourProp;
};

export const buildBadgeIconConfig = (
  variant: BadgeVariant,
): IconStyleConfig => {
  return {
    colour: `icons.on.${variant}`,
  };
};
