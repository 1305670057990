import dayjs, { Dayjs } from 'dayjs';
import { Translate } from 'next-translate';
import {
  SearchWidgetConfigFragment,
  PassengerRulesFragment,
  PassengerRuleFragment,
} from '@codegen/cmsUtils';
import {
  OfferFragment,
  SearchResultQueryVariables,
  SearchResultQuery,
  Offer,
  GetStationsQuery,
  OfferFilters,
  PickerCityFragment,
  PickerStationFragment,
  AvailabilityNodeFragment,
  PickerCountryFragment,
} from '@codegen/gatewayUtils';
import { CurrencyCode, Language, Partner, Sort } from '@shared/types/enums';
import { Item } from '@ui/components/Autocomplete/AutocompleteTypes';
import { findItemByCode } from '@ui/components/Autocomplete/utils/autoCompleteUtils';
import { PassengerTypeProp } from '@ui/components/PassengerCounter/passengerTypes';
import { sortArray } from '@utils/arrayUtils';
import { isDateInFuture, isSameDay, parseDateString } from '@utils/dateUtils';
import { createAnArray } from '@utils/helperUtils';
import { parseGatewayItinerary } from '@utils/itineraryUtils';
import {
  SearchQueryParams,
  SearchQueryParamsType,
  SearchQueryLocalStorageType,
} from '@web/types/searchWidgetTypes';
import { modifyTransferUrl } from '../offerUtils';

const constructPassengerTypeMinCount = ({
  passengerQueryParams,
  rule,
  rules,
}: {
  passengerQueryParams: { [paxType: string]: number[] };
  rule: PassengerRuleFragment;
  rules: PassengerRuleFragment[];
}) => {
  const guardianOfRule = rules.find(
    (aRule) => aRule.guardianPassengerType === rule.passengerType,
  );

  // If the current paxType has another paxType to be a guardian of,
  // we need to make sure we don't allow paxType to become larger than the minimum value of the current paxType
  if (guardianOfRule?.passengerType) {
    const guardianOfCurrentCount =
      passengerQueryParams[guardianOfRule.passengerType]?.length;

    return guardianOfCurrentCount !== undefined &&
      rule.minCount &&
      rule.minCount > guardianOfCurrentCount
      ? rule.minCount
      : guardianOfCurrentCount;
  }

  return rule.minCount;
};

const constructPassengerTypeMaxCount = ({
  isAtMaxCount,
  passengerQueryParams,
  rule,
}: {
  isAtMaxCount: boolean;
  passengerQueryParams: { [paxType: string]: number[] };
  rule: PassengerRuleFragment;
}) => {
  const currentCount =
    passengerQueryParams[
      rule.passengerType as keyof typeof passengerQueryParams
    ]?.length;

  if (rule.guardianPassengerType && rule.guardianMaxPerPassengerType) {
    const currentGuardianCount =
      passengerQueryParams[rule.guardianPassengerType]?.length;

    return isAtMaxCount
      ? currentCount
      : Number(currentGuardianCount) * rule.guardianMaxPerPassengerType;
  }

  return isAtMaxCount ? currentCount : rule.maxCount;
};

export const constructPassengerTypes = ({
  passengerQueryParams,
  passengerRules,
}: {
  passengerQueryParams: { [paxType: string]: number[] };
  passengerRules: PassengerRulesFragment;
}): PassengerTypeProp[] => {
  const totalCount = Object.keys(passengerQueryParams).reduce<number>(
    (acc, key) => (passengerQueryParams[key]?.length ?? 0) + acc,
    0,
  );

  const isAtMaxCount = passengerRules.maxCount
    ? passengerRules.maxCount <= totalCount
    : false;

  const sortedPassengerRules = sortArray({
    array: passengerRules.rules,
    key: 'minAge',
    reverse: true,
  });

  const types = sortedPassengerRules.map((rule, index) => ({
    id: index.toString(),
    type: rule.passengerType || '',
    label: rule.label.value || '',
    minAge: rule.minAge,
    maxAge: rule.maxAge,
    minCount: constructPassengerTypeMinCount({
      rule,
      passengerQueryParams,
      rules: sortedPassengerRules,
    }),
    maxCount: constructPassengerTypeMaxCount({
      isAtMaxCount,
      rule,
      passengerQueryParams,
    }),
    count:
      passengerQueryParams[
        rule.passengerType as keyof typeof passengerQueryParams
      ]?.length ?? 0,
    ages: passengerQueryParams[
      rule.passengerType as keyof typeof passengerQueryParams
    ],
  }));

  return types;
};

export const constructNewPaxAges = ({
  currentPaxTypeAges = [],
  newAge,
  paxType,
  updateIndex,
}: {
  currentPaxTypeAges?: number[];
  newAge: number;
  paxType: string;
  updateIndex: number;
}) => ({
  [paxType]: currentPaxTypeAges.map((age, ageIndex) =>
    ageIndex === updateIndex ? newAge : age,
  ),
});

export const constructAddPaxQueryParams = ({
  currentPaxTypeAges = [],
  passengerRules,
  paxType,
}: {
  currentPaxTypeAges?: number[];
  passengerRules: PassengerRulesFragment;
  paxType: string;
}) => {
  const defaultPaxValue =
    getPaxTypeMaxAge(passengerRules, paxType) ||
    getPaxTypeMinAge(passengerRules, paxType) ||
    NaN;

  return {
    [paxType]: [...currentPaxTypeAges, defaultPaxValue],
  };
};

export const constructRemovePaxQueryParams = ({
  currentPaxTypeAges = [],
  paxType,
}: {
  currentPaxTypeAges?: number[];
  paxType: string;
}) => ({
  [paxType]: currentPaxTypeAges.slice(0, -1),
});

export const getPaxTypeMinAge = (
  passengerRules: PassengerRulesFragment | null,
  paxType: string,
) =>
  passengerRules?.rules.find((rule) => rule.passengerType === paxType)
    ?.minAge || 0;

export const getPaxTypeMaxAge = (
  passengerRules: PassengerRulesFragment | null,
  paxType: string,
) =>
  passengerRules?.rules.find((rule) => rule.passengerType === paxType)?.maxAge;

export const getPaxTypeMinCount = (
  passengerRules: PassengerRulesFragment,
  paxType: string,
) =>
  passengerRules.rules.find((rule) => rule.passengerType === paxType)
    ?.minCount || 0;

export const getSearchQueryParamsFromLocalStorage = (
  passengerRules: Maybe<PassengerRulesFragment>,
) => {
  if (typeof Storage === 'undefined' || !passengerRules) {
    return null;
  }

  const lsOrigins = localStorage.getItem(SearchQueryParams.ORIGINS);
  const lsDestinations = localStorage.getItem(SearchQueryParams.DESTINATIONS);

  return {
    origins: lsOrigins,
    destinations: lsDestinations,
    currency: localStorage.getItem(SearchQueryParams.CURRENCY),
  };
};

export const removeSearchQueryParamFromLocalStorage = (key: string) => {
  if (typeof Storage === 'undefined' || !key) {
    return null;
  }

  localStorage.removeItem(key);
};

export const setSearchQueryParamToLocalStorage = (
  key: string,
  value: string,
) => {
  if (typeof Storage === 'undefined' || !key || !value) {
    return null;
  }

  localStorage.setItem(key, value);
};

export const constructStationQueryParameters = (
  originalItems: Item[],
  item: Item,
) => {
  const originalItem = findItemByCode(originalItems, item.code);

  if (originalItem?.subItems && originalItem.subItems.length > 0) {
    return originalItem.subItems.map((subItem) => subItem.code);
  }

  return [item.code];
};

export const constructSearchQueryParams = (
  queryParams: SearchQueryParamsType,
  passengerRules: Maybe<PassengerRulesFragment>,
  defaultParams?: Maybe<SearchQueryLocalStorageType>,
) => {
  const {
    currency = defaultParams?.currency,
    departureDate = null,
    destinations = defaultParams?.destinations,
    isOneWay = false,
    origins = defaultParams?.origins,
    residency = defaultParams?.residency,
    returnDate = null,
    ...passengerQueryParams
  } = queryParams;

  const isOneWayParsed = isOneWay === 'true';

  return {
    origins: origins?.split(',').filter((origin) => origin) || [],
    destinations:
      destinations === '*'
        ? []
        : destinations?.split(',').filter((destination) => destination) || [],
    departureDate: departureDate ? parseDateString(departureDate) : null,
    returnDate: returnDate ? parseDateString(returnDate) : null,
    isOneWay: isOneWayParsed,
    currency,
    residency,
    paxTypeAges: passengerRules
      ? passengerRules.rules.reduce<{
          [paxType: string]: number[];
        }>(
          (acc, rule) =>
            rule.passengerType
              ? {
                  ...acc,
                  [rule.passengerType]:
                    (passengerQueryParams[rule.passengerType] !== '' &&
                      passengerQueryParams[rule.passengerType]
                        ?.split(',')
                        .map((paxAge) => Number(paxAge))) ||
                    (defaultParams && rule.passengerType in defaultParams
                      ? defaultParams[
                          rule.passengerType as keyof typeof defaultParams
                        ]
                          ?.split(',')
                          .map((paxAge: string) => Number(paxAge))
                      : null) ||
                    createAnArray(
                      getPaxTypeMinCount(passengerRules, rule.passengerType),
                      getPaxTypeMaxAge(passengerRules, rule.passengerType) ||
                        getPaxTypeMinAge(passengerRules, rule.passengerType),
                    ),
                }
              : acc,
          {},
        )
      : {},
  };
};

export const constuctOfferQueryVariables = ({
  activeFilters,
  activeSort,
  currency,
  departureDate,
  destinations,
  isOneWay,
  language,
  limit,
  origins,
  partner,
  paxTypeAges,
  residency,
  returnDate,
  utmSource,
}: {
  activeFilters: OfferFilters | null;
  activeSort: Sort;
  currency?: CurrencyCode;
  departureDate: Dayjs | null;
  destinations: string[];
  isOneWay: boolean;
  language: Language;
  limit: number;
  origins: string[];
  partner: Partner;
  paxTypeAges: { [paxType: string]: number[] };
  residency?: string;
  returnDate: Dayjs | null;
  utmSource?: string;
}): SearchResultQueryVariables => ({
  partner,
  metadata: {
    language,
    currency: currency || undefined,
    country: residency || undefined,
  },
  origin: origins.join(','),
  destination: destinations.join(','),
  departureDateString: dayjs(departureDate).format('YYYY-MM-DD'),
  returnDateString: isOneWay ? null : dayjs(returnDate).format('YYYY-MM-DD'),
  passengerAges: Object.values(paxTypeAges).reduce<number[]>(
    (acc, ages) => [...acc, ...ages],
    [],
  ),
  filters: activeFilters,
  sort: activeSort,
  limit,
  utmSource,
});

export const constructOffer = ({
  newBookingFlowEnvironments,
  offer,
}: {
  newBookingFlowEnvironments?: string[];
  offer: OfferFragment;
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
}) => {
  const transferURL = modifyTransferUrl(
    offer.transferURL,
    newBookingFlowEnvironments,
  );

  return {
    ...offer,
    itinerary: parseGatewayItinerary(offer.itinerary),
    transferURL,
  } as Offer;
};

export const constructOffers = ({
  data,
  newBookingFlowEnvironments,
  utmCampaign,
  utmMedium,
  utmSource,
}: {
  data?: SearchResultQuery;
  newBookingFlowEnvironments?: string[];
  utmCampaign?: string;
  utmMedium?: string;
  utmSource?: string;
}): Offer[] => {
  const offersData = data?.search?.offers || [];
  const offers = offersData.map((offer) =>
    constructOffer({
      offer: offer as OfferFragment,
      utmSource,
      utmMedium,
      utmCampaign,
      newBookingFlowEnvironments,
    }),
  );

  return offers;
};

export const constructSubItemValue = (
  item: PickerCityFragment | PickerStationFragment,
  t: Translate,
) => {
  const hasStations = 'stations' in item && item.stations.length > 0;
  const code = hasStations ? `(${t('Any')})` : `(${item.code})`;
  const isTrain = item.transportType === 'TRAIN';

  return `${item.name}${!isTrain ? ` ${code}` : ''}`;
};

export const getRankCities = (item: PickerCityFragment) => {
  const ranks = item.stations.map((station) => station.rank);
  const maxRank = Math.max(...ranks);

  return maxRank !== -Infinity ? maxRank : 0;
};

export const getRankCountries = (item: Maybe<PickerCountryFragment>) => {
  if (!item) {
    return 0;
  }
  const ranks = item.cities.map((city) => getRankCities(city));
  const maxRank = Math.max(...ranks);

  return maxRank !== -Infinity ? maxRank : 0;
};

// Calls the stations endpoint and returns items that can be used inside our search autocomplete
export const constructItems = ({
  data,
  t,
}: {
  data: GetStationsQuery;
  t: Translate;
}): Item[] =>
  data.stations.map((station) => ({
    value: station?.name,
    code: station?.code,
    rank: getRankCountries(station),
    subItems: station?.cities.map((city) => ({
      value: constructSubItemValue(city, t),
      code: city.code,
      rank: getRankCities(city),
      isSelectable: true,
      transportType: city.transportType,
      subItems: city.stations.map((cityStation) => ({
        value: constructSubItemValue(cityStation, t),
        code: cityStation.code,
        rank: cityStation.rank,
        transportType: cityStation.transportType,
        isSelectable: true,
        subItems: [],
      })),
    })),
    isSelectable: false,
  })) as Item[];

export const getSearchWidgetDefaultState = ({
  departureDate,
  destinations,
  isOneWay,
  origins,
  passengerRules,
  paxTypeAges,
  returnDate,
}: {
  departureDate?: Maybe<Dayjs>;
  destinations?: Maybe<string[]>;
  isOneWay?: Maybe<boolean>;
  origins?: Maybe<string[]>;
  passengerRules: PassengerRulesFragment;
  paxTypeAges?: Maybe<{ [key: string]: number[] }>;
  returnDate?: Maybe<Dayjs>;
}) => ({
  origins: origins || [],
  destinations: destinations || [],
  departureDate,
  returnDate: isOneWay ? null : returnDate,
  isOneWay: isOneWay || false,
  paxTypeAges:
    paxTypeAges ||
    passengerRules.rules.reduce<{
      [paxType: string]: number[];
    }>(
      (acc, rule) =>
        rule.passengerType
          ? {
              ...acc,
              [rule.passengerType]: createAnArray(
                getPaxTypeMinCount(passengerRules, rule.passengerType),
                getPaxTypeMaxAge(passengerRules, rule.passengerType) ||
                  getPaxTypeMinAge(passengerRules, rule.passengerType),
              ),
            }
          : acc,
      {},
    ),
});

export const constructSearchWidgetConfig = (
  searchWidgetConfig?: Maybe<SearchWidgetConfigFragment>,
) => {
  if (searchWidgetConfig) {
    return {
      variant: searchWidgetConfig.variant,
    };
  }

  return {
    variant: null,
  };
};

export const getClosestAvailableDate = (
  selectedDate: Dayjs,
  availableDays?: Maybe<AvailabilityNodeFragment>[],
) => {
  return availableDays?.find((day) => {
    return (
      day &&
      isDateInFuture(day.date) &&
      dayjs(day.date).diff(selectedDate, 'day') > 0
    );
  })?.date;
};

export const getClosestAvailableRountrip = ({
  availableDepatureDays,
  checkIfArrivalDateIsDisabled,
  departureDate,
  returnDate,
}: {
  availableDepatureDays?: Maybe<AvailabilityNodeFragment>[];
  checkIfArrivalDateIsDisabled: (date: Dayjs, departureDate?: Dayjs) => boolean;
  departureDate: Dayjs;
  returnDate: Dayjs;
}) => {
  const numberOfDays = returnDate.diff(departureDate, 'day');

  // Loop through the available departure days and find the first available return date that is 'numberOfDays' away from the departure date
  const closestAvailableDepartureDate = availableDepatureDays?.find(
    (availableDepartureDate) =>
      !checkIfArrivalDateIsDisabled(
        dayjs(availableDepartureDate?.date).add(numberOfDays),
      ) &&
      // And is not the same date as the user has picked
      !dayjs(availableDepartureDate?.date).isSame(departureDate),
  );

  if (!closestAvailableDepartureDate) {
    return [];
  }

  const secondClosestAvailableDepartureDate = availableDepatureDays?.find(
    (availableDepartureDate) =>
      !checkIfArrivalDateIsDisabled(
        dayjs(availableDepartureDate?.date).add(numberOfDays),
      ) &&
      dayjs(availableDepartureDate?.date).isAfter(
        closestAvailableDepartureDate.date,
      ) &&
      // And is not the same date as the user has picked
      !dayjs(availableDepartureDate?.date).isSame(departureDate),
  );

  return [
    {
      departureDate: closestAvailableDepartureDate.date,
      returnDate: dayjs(closestAvailableDepartureDate.date)
        .add(numberOfDays, 'day')
        .toString(),
    },
    ...(secondClosestAvailableDepartureDate
      ? [
          {
            departureDate: secondClosestAvailableDepartureDate.date,
            returnDate: dayjs(secondClosestAvailableDepartureDate.date)
              .add(numberOfDays, 'day')
              .toString(),
          },
        ]
      : []),
  ];
};

export const parseDaysResponse = (days: AvailabilityNodeFragment[]) => {
  return days.map((d) => {
    return { ...d, date: parseDateString(d.date) };
  });
};

export const findDate = (
  date: Dayjs,
  days: Maybe<AvailabilityNodeFragment>[],
) => {
  return (
    days.find((day) =>
      day?.date ? isSameDay(parseDateString(day.date), date) : null,
    ) ?? null
  );
};

export const findDateByType = ({
  arrivalDays,
  date,
  departureDate,
  departureDays,
  isOneWay,
  returnDate,
}: {
  arrivalDays: Maybe<AvailabilityNodeFragment>[];
  date: Dayjs;
  departureDate: Maybe<Dayjs>;
  departureDays: Maybe<AvailabilityNodeFragment>[];
  isOneWay?: boolean;
  returnDate: Maybe<Dayjs>;
}) => {
  if (!departureDate || isOneWay) {
    return findDate(date, departureDays);
  }

  if (!returnDate) {
    return findDate(date, arrivalDays);
  }

  // If the departureDate and returnDate have been set and this date is the same as the returnDate
  // We show the returnDate price since that date is selected as the returnDate
  if (isSameDay(date, returnDate)) {
    return findDate(date, arrivalDays);
  }

  return findDate(date, departureDays);
};
