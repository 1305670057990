import { ElementType, MouseEventHandler, ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ImageWithConfigFragment } from '@codegen/cmsUtils';
import { ThemeMarginProps } from '@ui-v2/types/props';
import { buildResponsiveValues } from '@ui-v2/utils/buildResponsiveValues';
import { buildMargin } from '@ui-v2/utils/themePropBuilders';
import Box from '../Box/Box';
import Icon from '../Icon/Icon';
import Text from '../Text/Text';
import { buildBadgeIconConfig, buildBadgeVariant } from './variants';

export type BadgeVariant = 'information' | 'success' | 'warning' | 'critical';

export type BadgeProps = ThemeMarginProps & {
  as?: ElementType;
  children?: ReactNode;
  icon?: ImageWithConfigFragment | null;
  onClick?: MouseEventHandler;
  variant?: BadgeVariant;
};

type StyledBadgeProps = ThemeMarginProps & Pick<BadgeProps, 'variant' | 'as'>;

export const StyledBadgeBox = styled(Box)<StyledBadgeProps>(
  ({ as, theme, variant = 'information', ...props }) => [
    css`
      border: none;
    `,
    buildResponsiveValues({
      ...buildMargin(props),
    }),
    buildBadgeVariant(variant, theme),
    as === 'button' &&
      css`
        cursor: pointer;
        transition: box-shadow 200ms;

        &:hover {
          box-shadow: ${theme.shadows.low};
        }
      `,
  ],
);

const Badge = ({
  as,
  children,
  icon,
  onClick,
  variant = 'information',
  ...props
}: BadgeProps) => {
  return (
    <StyledBadgeBox
      {...props}
      alignItems="center"
      borderRadius="borderRadiusXxl"
      display="inline-flex"
      flex="0 0 auto"
      gap={4}
      onClick={onClick}
      px={8}
      py={4}
      variant={variant}
      {...(as && { as, type: as === 'button' ? 'button' : undefined })}
    >
      {icon && (
        <Icon icon={icon} size={16} {...buildBadgeIconConfig(variant)} />
      )}
      <Text variant="heading-7">{children}</Text>
    </StyledBadgeBox>
  );
};

export default Badge;
